import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../../../hook/useStores';
import { Dialogs } from '../../../../stores/UCRStore';
import { Button, ButtonElems, ButtonColors, DatePrevNext } from '../../../v2/components';
import { IconAdd, IconRefresh } from '../../../../helpers/ucr/icons';
import { ActionsDropDown, ActionsButtonActions } from '../components/calendar/ActionsDropDown';
import { useView } from './useView';
import RootStore from '../../../../stores/RootStore';

export const CommonControls: FC<{
    actions?: ActionsButtonActions[];
    additionalLeft?: ReactNode;
    additionalRight?: ReactNode;
    disableDatePicker?: boolean;
    disableAddVisit?: boolean;
    disableAddAdmin?: boolean;
    refresh?(): void;
}> = observer(
    ({
        actions = [],
        additionalLeft,
        additionalRight,
        disableDatePicker,
        disableAddVisit,
        disableAddAdmin,
        refresh,
    }) => {
        const {
            RootStore: {
                ucrStore: { setOpenedDialog, setSelectedDate },
            },
        } = useStores<{ RootStore: RootStore }>();
        const { clearDeepLink } = useView();

        const onChangeDate = (date: Date): void => {
            clearDeepLink();
            setSelectedDate(date);
        };

        return (
            <div className="common-controls">
                <div className="common-controls__left">
                    {actions?.length > 0 && (
                        <ActionsDropDown
                            color={ButtonColors.BLUE_DARK}
                            text="Actions"
                            actions={actions}
                        />
                    )}
                    {!disableAddVisit && (
                        <Button
                            name="Add visit"
                            Icon={IconAdd}
                            elem={ButtonElems.BUTTON}
                            className="ucr__controls-button"
                            clickEvent={() => setOpenedDialog(Dialogs.ADD_NEW_VISIT)}
                        />
                    )}
                    {!disableAddAdmin && (
                        <Button
                            name="Add admin"
                            Icon={IconAdd}
                            elem={ButtonElems.BUTTON}
                            className="ucr__controls-button"
                            clickEvent={() => setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME)}
                        />
                    )}
                    {additionalLeft}
                </div>

                <div className="common-controls__right">
                    {refresh && (
                        <Button
                            name="Refresh"
                            Icon={IconRefresh}
                            elem={ButtonElems.BUTTON}
                            className="ucr__controls-button"
                            clickEvent={refresh}
                        />
                    )}
                    {additionalRight}
                    {!disableDatePicker && (
                        <DatePrevNext
                            name="ucr__filters-date"
                            className="ucr__controls-button ucr__filters-date"
                            dateFormat="dddd Do MMMM YYYY"
                            showTodayButton={true}
                            onChange={onChangeDate}
                        />
                    )}
                </div>
            </div>
        );
    },
);
