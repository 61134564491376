import { useMutation } from '@apollo/client';
import { JobStatus, Patient } from '@doc-abode/data-models';
import cn from 'classnames';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { SNAPS } from '../../../../../constants/snapGridConst';
import { UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { getAbortedDetails } from '../../../../../helpers/getAbortedDetails';
import { isAborted, isArrived, isCompleted } from '../../../../../helpers/statusCheckHelper';
import {
    getDateEndTime,
    getDurationInMinutes,
    getHcpId,
    getMomentEndTime,
    snapAssignedToGrid,
    snapUnassignedToGrid,
} from '../../../../../helpers/ucr';
import {
    getChangeConfirmationDialogMessage,
    isQualifyingStateForChangeConfirmationDialog,
} from '../../../../../helpers/ucr/changeConfirmationDialogHelper';
import { getOverlappingJobsArr } from '../../../../../helpers/ucr/getOverlappingJobs';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import { IJobPos, IJobsPosArr } from '../../../../../interfaces/ucr';
import RootStore from '../../../../../stores/RootStore';
import { DragContainers } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { FetchJobsContext } from '../../blocks/MainConst';
import { EnumJobContainer } from './Job/JobTypes';
import JobDraggable from './JobDraggable';

interface IProps {
    isSnapToGrid: boolean;
}

interface INewJobPos {
    job: Patient;
    pos: IJobPos;
    container: 'visits' | 'calendar';
    left: number;
    top: number;
}

/**
 * Utility to determine whether to use actual timestamps for display purposes
 * @param jobStatus
 * @param arrivedDateTime
 * @returns
 */
export const shouldUseActualsForDisplay = (
    jobStatus?: JobStatus,
    arrivedDateTime?: string | null,
): boolean => {
    const isJobArrived = isArrived({ jobStatus });
    const isJobCompleted = isCompleted({ jobStatus });
    const isJobAborted = isAborted({ jobStatus });

    return (isJobArrived || isJobCompleted || isJobAborted) && !!arrivedDateTime;
};

/**
 * Utility to calculate the display start and end times
 * @param job
 * @param isBuddy
 * @returns
 */
export const calculateDisplayStartAndEnd = (job: Patient, isBuddy: boolean = false) => {
    const actualEndDateTime = moment();

    const jobStatus = isBuddy ? job.buddyJobStatus : job.jobStatus;

    const isJobAborted = isAborted({ jobStatus });

    let arrivedDateTime = job.arrivedDateTime;
    let finishedDateTime = job.finishedDateTime;

    // Getting timestamps from the correct HCP
    if (isBuddy) {
        arrivedDateTime = job.buddyArrivedDateTime;
        finishedDateTime = job.buddyFinishedDateTime;
    }

    // If the job is aborted, data could come from HCP or Controller aborted
    if (isJobAborted) {
        const abortedDetails = getAbortedDetails(job, isBuddy);
        finishedDateTime = abortedDetails?.abortedDatetime;
    }

    const startDateTime = arrivedDateTime || job.startDateTime;
    const dateStart: Moment = moment(startDateTime);

    let momentEndDateTime = finishedDateTime
        ? moment(finishedDateTime)
        : getMomentEndTime(startDateTime, job.duration);

    const shouldSetNewDuration = shouldUseActualsForDisplay(jobStatus, arrivedDateTime);

    const isJobCurrentDay = actualEndDateTime.isSame(job.startDateTime, 'day');

    let hasNewEndTime = false;

    const getNewDuration = (): number => {
        if (finishedDateTime) {
            return moment(finishedDateTime).diff(startDateTime, 'minutes');
        }

        // Fallback to render arrived but not completed jobs from prior days with their planned duration
        if (!isJobCurrentDay) return getDurationInMinutes(job.duration);

        // If job don't have actual finished time
        if (actualEndDateTime.isAfter(momentEndDateTime)) {
            hasNewEndTime = true;
            return actualEndDateTime.diff(startDateTime, 'minutes');
        } else {
            return momentEndDateTime.diff(startDateTime, 'minutes');
        }
    };

    const duration = shouldSetNewDuration ? getNewDuration() : getDurationInMinutes(job.duration);

    const jobHourStart = dateStart.hours() + dateStart.minutes() / 60;
    const jobDuration = duration / 60;

    return {
        hasNewEndTime,
        dateStart,
        momentEndDateTime,
        jobHourStart,
        duration,
        jobDuration,
        actualEndDateTime,
    };
};

const prohibitedJobStatuses = [JobStatus.CONTROLLER_ABORTED, JobStatus.HCP_ABORTED];

const Container: FC<IProps> = ({ isSnapToGrid }) => {
    const {
        RootStore: {
            ucrStore: {
                calendarEl,
                cellWidth,
                hcpsWidth,
                hourStart,
                snapMinutes,
                hcpsOffset,
                hcpsPos,
                assignedJobs,
                jobsPosArr,
                setJobsPosArr,
                setDragContainer,
                setLoadingJobs,
                selectedDate,
                openAlert,
                closeAlert,
                expandedSwimlanes,
                jobs,
                focusedJobId,
                showAbortedJobs,
                updateDragged,
            },
            userStore: {
                user: { username },
            },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const fetchJobs = useContext(FetchJobsContext);

    const [updateJob, { loading }] = useMutation(UPDATE_JOB, {
        onCompleted: (data) => {
            updateDragged(data.updateJob.id!, false);
            fetchJobs.refreshJobs();
        },
    });

    useEffect(() => setLoadingJobs(loading), [setLoadingJobs, loading]);

    // Saving all the necessary data for the position in the calendar
    // (startDateTime and duration should be updated in the HCP)

    const getNewPosition = useCallback(
        (job: Patient, isSecondDoubleUpHcp: boolean = false): IJobPos => {
            const {
                hasNewEndTime,
                dateStart,
                momentEndDateTime,
                duration,
                jobDuration,
                jobHourStart,
                actualEndDateTime,
            } = calculateDisplayStartAndEnd(job, isSecondDoubleUpHcp);

            const jobPos: IJobPos = {
                jobId: job.id,
                hcpId: !isSecondDoubleUpHcp ? job.hcpId : job.buddyId,
                top: !isSecondDoubleUpHcp
                    ? hcpsPos[job.hcpId || '']?.top || 0
                    : hcpsPos[job.buddyId || '']?.top || 0,
                left: (jobHourStart - hourStart) * cellWidth,
                width: jobDuration * cellWidth,
                height: hcpsPos[job.hcpId || '']?.height || 0,
                startTime: dateStart.valueOf(),
                duration: duration.valueOf(),
                isExpanded: expandedSwimlanes[job.hcpId!] || false,
                isDoubleUp: isSecondDoubleUpHcp,
                isSecondDoubleUpHcp: isSecondDoubleUpHcp,
            };

            if (hasNewEndTime) {
                const jobPlanedHourEnd: number =
                    momentEndDateTime.hours() + momentEndDateTime.minutes() / 60;
                const plannedWidth = (jobPlanedHourEnd - jobHourStart) * cellWidth;

                jobPos.actualEndDateTime = actualEndDateTime.valueOf();
                jobPos.offsetWidth = jobDuration * cellWidth - Math.abs(plannedWidth);
            }

            return jobPos;
        },
        [hourStart, cellWidth, hcpsPos, expandedSwimlanes],
    );

    useEffect(() => {
        const newJobsPos: IJobsPosArr = {};

        assignedJobs.forEach((job: Patient) => {
            if (job.startDateTime) {
                const jobsWithPositionMapped = [getNewPosition(job)];
                if (isMultiAssigneeJob(job)) {
                    jobsWithPositionMapped.push(getNewPosition(job, true));
                }
                newJobsPos[job.id] = jobsWithPositionMapped;
            }
        });

        // VSU-506
        // Throttling prevents jobs jumping and flashing.
        // Just pushing to the next the next frame seems to be enough.
        const timeout = setTimeout(() => setJobsPosArr(newJobsPos));
        return () => clearTimeout(timeout);
    }, [cellWidth, hourStart, hcpsPos, assignedJobs, setJobsPosArr, getNewPosition]);

    // List of all jobs that can be moved
    const jobsList: JobInfo[] = useMemo(() => {
        const jobs = assignedJobs
            .filter(
                (job: any) =>
                    moment(job.startDateTime || job.dateOfVisit).isSame(selectedDate, 'day') &&
                    job.hcpId &&
                    hcpsPos[job.hcpId] &&
                    jobsPosArr[job.id],
            )
            .map((job: any) => {
                return { job, pos: jobsPosArr[job.id][0] };
            });

        return showAbortedJobs
            ? jobs
            : jobs.filter((j: any) => !prohibitedJobStatuses.includes(j.job.jobStatus));
    }, [assignedJobs, selectedDate, hcpsPos, jobsPosArr, showAbortedJobs]);

    const doubleUpVIsitList: JobInfo[] = useMemo(() => {
        const doubleUpVisits = (assignedJobs as Patient[]).filter(
            (job) =>
                moment(job.startDateTime || job.dateOfVisit).isSame(selectedDate, 'day') &&
                isMultiAssigneeJob(job) &&
                job.buddyId &&
                hcpsPos[job.buddyId],
        );

        const filteredDoubleUpVisits = showAbortedJobs
            ? doubleUpVisits
            : doubleUpVisits.filter(
                  (j) => !prohibitedJobStatuses.includes(j.buddyJobStatus as JobStatus),
              );

        return filteredDoubleUpVisits.map((job: Patient) => {
            const visitPosition: IJobPos = {
                ...getNewPosition(job, true),
                hcpId: job.buddyId,
                top: hcpsPos[job.buddyId || '']?.top || 0,
                height: hcpsPos[job.buddyId || '']?.height || 0,
                isDoubleUp: true,
                isSecondDoubleUpHcp: true,
                isExpanded: expandedSwimlanes[job.buddyId || ''] || false,
            };

            return { job, pos: visitPosition, isDoubleUpVisit: true };
        });
    }, [hcpsPos, assignedJobs, expandedSwimlanes, selectedDate, getNewPosition, showAbortedJobs]);

    // Snap width in pixels, relative to the column width
    const snapWidth: number = useMemo(() => {
        return Math.round(cellWidth / (60 / snapMinutes));
    }, [cellWidth, snapMinutes]);

    // Updating new job position parameters
    const updateJobPos = useCallback(
        async ({ job, pos, container, left, top }: INewJobPos) => {
            updateDragged(job.id, true);
            const hcpId: string = getHcpId({
                hcpsPos,
                top,
            });

            const isDoubleUp = isMultiAssigneeJob(job);

            // Moving double visits to the same HCP is not allowed.
            if (
                (pos.isDoubleUp && hcpId === job.hcpId) ||
                (!pos.isDoubleUp && hcpId === job.buddyId) ||
                (container === 'visits' && isDoubleUp && !job.buddyId && hcpId === job.hcpId) ||
                (container === 'visits' && isDoubleUp && !job.hcpId && hcpId === job.buddyId)
            ) {
                updateDragged(job.id, false);
                return;
            }

            const date: Moment = moment(selectedDate);

            date.hour(Math.floor(left / cellWidth));
            date.minute(
                Math.round(Math.floor(((left / cellWidth) % 1) * 60) / SNAPS.SNAP_TIME_TO) *
                    SNAPS.SNAP_TIME_TO,
            );

            const overlapping: string[] = getOverlappingJobsArr({
                hcpId,
                jobsPosArr,
                jobPos: pos,
                curLeft: pos.left,
            });

            const isUnassigned =
                job.jobStatus === JobStatus.PENDING ||
                (isDoubleUp && job.buddyJobStatus === JobStatus.PENDING);

            const startTimeWithSelectedDate =
                moment(job.startDateTime).format('DD MM YYYY') ===
                moment(selectedDate).format('DD MM YYYY')
                    ? job.startDateTime
                    : moment(selectedDate).set({
                          hour: moment(job.startDateTime).hour(),
                          minute: moment(job.startDateTime).minute(),
                      });

            const startDateTime =
                container === 'calendar' || (container === 'visits' && !job.startDateTime)
                    ? moment(date).add(hourStart, 'hours').seconds(0)
                    : moment(job.arrivedDateTime || startTimeWithSelectedDate).seconds(0);
            const arrivedDateTime =
                container === 'calendar'
                    ? moment(job.arrivedDateTime) || moment(date).add(hourStart, 'hours')
                    : moment(job.arrivedDateTime);
            const duration = moment.duration(
                moment(job?.finishedDateTime).diff(job.arrivedDateTime),
            );

            const finishedDateTime = arrivedDateTime.clone().add(duration);
            const endDateTime = startDateTime.clone().add(job.duration).seconds(0);

            const isJobArrived = job.jobStatus === JobStatus.ARRIVED;
            const isBuddyArrived = job.buddyJobStatus === JobStatus.ARRIVED;
            const isJobCurrent = job.jobStatus === JobStatus.CURRENT;
            const isBuddyCurrent = job.buddyJobStatus === JobStatus.CURRENT;
            const isJobCompleted = job.jobStatus === JobStatus.COMPLETED;
            const isSecondDoubleUpHcp = pos.isSecondDoubleUpHcp;

            const currentJob = jobs.find((job: Patient) => job.id === focusedJobId);
            const isJobChanged = currentJob?.startDateTime !== startDateTime.toISOString();

            const isJobPositionChanged = (isJobArrived || isJobCurrent) && job?.hcpId !== hcpId;
            const isBuddyPositionChanged =
                (isBuddyCurrent || isBuddyArrived) && job?.buddyId !== hcpId;

            let input: Partial<Patient> = {
                id: job.id,
                version: job.version + 1,
                lastUpdatedBy: username,
                dateOfVisit: moment(selectedDate).startOf('day').hour(12).toISOString(),
                startDateTime: startDateTime.toISOString(),
                endDateTime: isJobArrived
                    ? getDateEndTime(job.arrivedDateTime, job.duration).toISOString()
                    : !isJobCompleted
                      ? endDateTime.toISOString()
                      : undefined,
                arrivedDateTime: isJobCompleted
                    ? arrivedDateTime.toISOString()
                    : startDateTime.toISOString(),
                finishedDateTime: isJobCompleted
                    ? finishedDateTime.toISOString()
                    : endDateTime.toISOString(),
                lastUpdatedDateTime: isJobChanged ? moment().toISOString() : undefined,
            };
            if (!isJobCompleted) {
                delete input.arrivedDateTime;
                delete input.finishedDateTime;
            }
            if (isSecondDoubleUpHcp) {
                input.buddyId = hcpId || null;
                input.buddyJobStatus =
                    job.buddyJobStatus === JobStatus.PENDING || isBuddyPositionChanged
                        ? JobStatus.ACCEPTED
                        : job.buddyJobStatus;
                isBuddyPositionChanged && (input.buddyArrivedDateTime = null);
                input.buddyName = users?.find((user: any) => hcpId === user.userId)?.userName;
            } else {
                input.hcpId = hcpId || null;
                input.hcpName = users?.find((user: any) => hcpId === user.userId)?.userName;
                input.jobStatus =
                    job.jobStatus === JobStatus.PENDING || isJobPositionChanged
                        ? JobStatus.ACCEPTED
                        : job.jobStatus;
                isJobPositionChanged && (input.arrivedDateTime = null);
            }

            const posDetails = {
                jobId: job.id,
                offsetWidth: pos.offsetWidth,
                isExpanded: pos.isExpanded,
                top,
                left,
                width: pos.width,
                height: hcpsPos[hcpId].height,
                overlapping,
                startDateTime: startDateTime.valueOf(),
                endDateTime: endDateTime.valueOf(),
            };

            const oldPositions = jobsPosArr[job.id];

            let newJobPositions: [IJobPos] | [IJobPos, IJobPos];

            if (isSecondDoubleUpHcp) {
                if (oldPositions) {
                    newJobPositions = [oldPositions[0], posDetails];
                } else {
                    newJobPositions = [posDetails];
                }
            } else if (!oldPositions || oldPositions.length === 1) {
                newJobPositions = [posDetails];
            } else {
                newJobPositions = [posDetails, oldPositions[1]];
            }

            let newJobsPos: IJobsPosArr = {
                ...jobsPosArr,
                [job.id]: newJobPositions,
            };

            try {
                const isSameDay = moment(input.startDateTime).isSame(
                    job.startDateTime || job.dateOfVisit,
                    'day',
                );

                if (isSameDay || isUnassigned) {
                    setJobsPosArr(newJobsPos);
                    updateJob({
                        variables: { input },
                    });
                } else {
                    updateDragged(job.id, false);
                }
            } catch (err: any) {
                AppToaster.show({
                    message: `Error updating job: ${err.message}`,
                    intent: 'danger',
                });
                updateDragged(job.id, false);
            }
        },
        [
            updateDragged,
            hcpsPos,
            selectedDate,
            cellWidth,
            jobsPosArr,
            hourStart,
            jobs,
            username,
            focusedJobId,
            users,
            setJobsPosArr,
            updateJob,
        ],
    );

    // Calculating the new position of the job on drop
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: 'box',
            drop(
                item: {
                    job: Patient;
                    pos: IJobPos;
                    container: 'visits' | 'calendar';
                },
                monitor,
            ) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const offset = monitor.getSourceClientOffset();

                const isDoubleUp = isMultiAssigneeJob(item.job);
                const isSecondUser = Boolean(isDoubleUp && item.pos.isSecondDoubleUpHcp);
                const jobStatus = isSecondUser ? item.job.buddyJobStatus : item.job.jobStatus;

                if (prohibitedJobStatuses.includes(jobStatus as JobStatus) || !delta || !offset) {
                    return;
                }

                let left: number = 0;
                let top: number = 0;

                if (item.container === 'visits') {
                    if (
                        !moment(selectedDate).isSame(
                            item.job.startDateTime || item.job.dateOfVisit,
                            'day',
                        ) &&
                        ((isDoubleUp && item.job.hcpId && !item.job.buddyId) ||
                            (isDoubleUp && item.job.buddyId && !item.job.hcpId))
                    ) {
                        AppToaster.show({
                            message:
                                'This visit is part of a double-up and cannot be scheduled for the chosen date.',
                            intent: 'danger',
                        });
                        return;
                    }
                    if (moment().isAfter(selectedDate, 'day')) {
                        AppToaster.show({
                            message: 'Visits cannot be scheduled for past days',
                            intent: 'danger',
                        });
                        return;
                    }

                    // could be undefined.
                    if (calendarEl) {
                        const calendarTop =
                            calendarEl.getBoundingClientRect().top + hcpsOffset + window.scrollY;
                        const calendarLeft =
                            calendarEl.getBoundingClientRect().left + hcpsWidth + window.scrollX;

                        left = offset.x - calendarLeft + calendarEl.scrollLeft;
                        top = offset.y - calendarTop + calendarEl.scrollTop;
                    }

                    if (isSnapToGrid) {
                        const [x, y] = snapUnassignedToGrid({
                            pos: {
                                left,
                                top,
                            },
                            snapWidth,
                            hcpsPos,
                        });
                        left = x;
                        top = y;
                    }
                } else {
                    left = Math.round(item.pos.left + delta.x);
                    top = Math.round(item.pos.top + delta.y);

                    if (isSnapToGrid) {
                        const [x, y] = snapAssignedToGrid({
                            delta,
                            pos: {
                                left: item.pos.left,
                                top: item.pos.top,
                            },
                            snapWidth,
                            hcpsPos,
                        });
                        left =
                            Math.ceil(Math.round(item.pos.left + x) / SNAPS.SNAP_TO) *
                            SNAPS.SNAP_TO;
                        top = Math.round(item.pos.top + y);
                    }
                }

                const newJobPos: INewJobPos = {
                    job: item.job,
                    pos: item.pos,
                    container: item.container,
                    left,
                    top,
                };

                const hasFuturePlannedDate =
                    moment(selectedDate).isBefore(item.job.dateOfVisit, 'day') &&
                    item.container === 'visits';
                const hasFutureEarliestDate =
                    moment(selectedDate).isBefore(item.job.earliestDateOfVisit, 'day') &&
                    item.container === 'visits';
                const isUserInTheFutureDate =
                    moment().isBefore(selectedDate, 'day') &&
                    moment().isBefore(item.job.dateOfVisit, 'day') &&
                    moment(selectedDate).isAfter(item.job.dateOfVisit, 'day') &&
                    item.container === 'visits';

                let message = '';

                if (isQualifyingStateForChangeConfirmationDialog(jobStatus)) {
                    message = getChangeConfirmationDialogMessage(jobStatus);
                } else if (isUserInTheFutureDate) {
                    message = getAlertMessageByJobStatus('userInTheFutureDate', item.job);
                } else if (hasFuturePlannedDate && hasFutureEarliestDate) {
                    message = getAlertMessageByJobStatus(
                        'hasFuturePlanedAndFutureEarliestDate',
                        item.job,
                    );
                } else if (hasFuturePlannedDate) {
                    message = getAlertMessageByJobStatus('hasFuturePlannedDate', item.job);
                } else if (hasFutureEarliestDate) {
                    message = getAlertMessageByJobStatus('hasFutureEarliestDate', item.job);
                }

                // Show confirmation message if necessary
                if (message) {
                    openAlert({
                        message,
                        isOpen: true,
                        onConfirm: () => {
                            updateJobPos(newJobPos);
                            closeAlert();
                        },
                    });

                    return;
                }

                updateJobPos(newJobPos);
                return;
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
            }),
        }),
        [updateJobPos],
    );

    useEffect(() => {
        setDragContainer(isOver ? DragContainers.CALENDAR : DragContainers.NONE);
    }, [isOver, setDragContainer]);

    return (
        <div ref={drop} className="ucr__calendar-container">
            <JobList jobs={[...jobsList, ...doubleUpVIsitList]} positions={{ ...jobsPosArr }} />
            <div
                className={cn('ucr__calendar-container-placeholder', {
                    'ucr__calendar-container-placeholder--hover': isOver,
                })}
            />
        </div>
    );
};

export default observer(Container);

interface JobInfo {
    job: Patient;
    pos: IJobPos;
    isDoubledUp?: boolean;
}

interface JobListProps {
    jobs: JobInfo[];
    positions: IJobsPosArr;
}

/**
 * Renders Jobs in a z-index order ensuring, by status,
 * so that the most important jobs are in front and least are behind
 */
const JobList: FC<JobListProps> = observer(({ jobs, positions }): any => {
    const {
        RootStore: {
            ucrStore: { expandedSwimlanes },
        },
    } = useStores<{ RootStore: RootStore }>();

    return jobs
        .sort((a, b) => {
            const A = JobStatusRank(a.job);
            const B = JobStatusRank(b.job);

            return A === B ? 0 : A < B ? -1 : 1;
        })
        .map(({ job, pos }) => {
            const newPosition =
                positions && positions[job.id]
                    ? pos?.isDoubleUp
                        ? positions[job.id][1]
                        : positions[job.id][0]
                    : pos;

            if (newPosition) {
                newPosition.isExpanded = expandedSwimlanes[newPosition.hcpId!] ? true : false;
            }

            return (
                <JobDraggable
                    key={`${pos.isDoubleUp ? 'double up' : ''}-${job.id}`}
                    pos={{ ...newPosition }}
                    job={job}
                    container={EnumJobContainer.CALENDAR}
                />
            );
        });
});

/**
 * Get a visual rank of a job, so that higher ranked
 * statuses are placed in front of lower ranked statuses
 */
function JobStatusRank(job: Patient): number {
    switch (job.jobStatus) {
        case JobStatus.ACCEPTED:
            return 10;
        case JobStatus.CURRENT:
            return 9;
        case JobStatus.ARRIVED:
            return 8;
        case JobStatus.COMPLETED:
            return 7;
        case JobStatus.HCP_ABORTED:
        case JobStatus.CONTROLLER_ABORTED:
            return -1;
        // Not handled in UCR
        case JobStatus.AVAILABLE:
        case JobStatus.WITHDRAWN:
        case JobStatus.PENDING:
        default:
            return 0;
    }
}

type JobTimeStatus =
    | 'userInTheFutureDate'
    | 'hasFuturePlanedAndFutureEarliestDate'
    | 'hasFuturePlannedDate'
    | 'hasFutureEarliestDate'
    | '';

function getAlertMessageByJobStatus(jobTimeStatus: JobTimeStatus, job: Patient): string {
    const alertMessages = {
        userInTheFutureDate: `The visit is planned for ${moment(job.dateOfVisit).format(
            'DD-MMM-YYYY',
        )}. Are you sure you want to assign the visit to a later date?`,
        hasFuturePlanedAndFutureEarliestDate: `The visit is planned for ${moment(
            job.dateOfVisit,
        ).format('DD-MMM-YYYY')} and has an earliest date of visit of ${moment(
            job.earliestDateOfVisit,
        ).format('DD-MMM-YYYY')}. Are you sure you want to assign the visit to an earlier date?`,
        hasFuturePlannedDate: `The visit is planned for ${moment(job.dateOfVisit).format(
            'DD-MMM-YYYY',
        )}. Are you sure you want to assign the visit to an earlier date?`,
        hasFutureEarliestDate: `The visit has an earliest date of visit of ${moment(
            job.earliestDateOfVisit,
        ).format('DD-MMM-YYYY')}. Are you sure you want to assign the visit to an earlier date?`,
        '': '',
    };

    return alertMessages[jobTimeStatus];
}
