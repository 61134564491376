import { Schedule } from '@doc-abode/data-models';
import { useMemo } from 'react';

import { VisitValuesType } from '../components/pages/ucr/blocks/panels/VisitDetailsTypes';
import { filterSchedules } from '../helpers/ucr/filterSchedules';
import { filterWarnings } from '../helpers/ucr/filterWarnings';
import { formatScheduleStartAndEndForDisplayLabel } from '../helpers/ucr/formatSchedules';
import { WarningCategories } from '../helpers/ucr/getWarnings';
import { getSortedHcpHours } from '../helpers/ucr/sortSchedules';
import { IHcp, WarningType } from '../interfaces/ucr';
import RootStore from '../stores/RootStore';
import useStores from './useStores';
import { useScheduleWarnings } from './useVisitWarnings';

export enum WorkScheduleLabel {
    NOT_AT_WORK = 'Not at work',
    WORKING_DURING_PLANNED_TIME = 'Working during planned time',
}

export function useHcpSuggestionOptions(patient: VisitValuesType) {
    const {
        RootStore: {
            usersStore: { hcpUsers, getEnabledUsers, getUserBand },
            schedulesStore: { allSchedulesByDate },
        },
    } = useStores<{ RootStore: RootStore }>();

    const activeUsers = useMemo(() => {
        return getEnabledUsers(hcpUsers);
    }, [hcpUsers, getEnabledUsers]);

    const hcpIds = useMemo(() => {
        return activeUsers.map(({ userId }: { userId: string }) => userId);
    }, [activeUsers]);

    // Fudge to make sure the info panel works correct
    // The info panel works of a job and not this made-up interface we use in View, Create, Edit and Review jobs (and prep of follow-up jobs)
    // TODO https://docabode.atlassian.net/browse/VSU-3092 to address the issue
    if (!patient.visitDate && (patient as any).dateOfVisit) {
        patient.visitDate = (patient as any).dateOfVisit;
        patient.startTime = (patient as any).startDateTime;
    }

    const visitScheduleWarnings = useScheduleWarnings(patient, hcpIds);

    return useMemo(() => {
        return [
            ...activeUsers.map((user) => {
                return getScheduleOptionForHcp(
                    user,
                    formatBandForScheduleOptionForHcp(getUserBand(user.userId)),
                    allSchedulesByDate,
                    visitScheduleWarnings,
                );
            }),
        ];
    }, [activeUsers, allSchedulesByDate, visitScheduleWarnings, getUserBand]);
}

export function getAvailableHours(
    hcpSchedules: Schedule[],
    isSick: boolean,
    visitWarnings: WarningType[],
    hcpId?: string,
) {
    if (!hcpSchedules?.length || isSick) return WorkScheduleLabel.NOT_AT_WORK;

    const schedulesConflictWarnings = filterWarnings({
        warnings: visitWarnings,
        category: WarningCategories.HCP_AVAILABILITY_CONFLICT,
        userId: hcpId,
    });

    if (schedulesConflictWarnings?.length) {
        const sortedHcpSchedules = getSortedHcpHours(hcpSchedules);

        let workingHoursLabel = '';

        // Append each of the working hours
        sortedHcpSchedules.forEach(
            (schedule) =>
                (workingHoursLabel = workingHoursLabel.concat(
                    formatScheduleStartAndEndForDisplayLabel(schedule),
                    '; ',
                )),
        );

        // Trim the final trailing semi-colon and space
        workingHoursLabel = workingHoursLabel.slice(0, workingHoursLabel.length - 2);

        return `Working: ${workingHoursLabel}`;
    }

    return WorkScheduleLabel.WORKING_DURING_PLANNED_TIME;
}

export function formatBandForScheduleOptionForHcp(band?: string | null) {
    return band ? `( ${band} )` : '';
}

export function getScheduleOptionForHcp(
    hcp: IHcp,
    bandLabel: string,
    schedules: Schedule[],
    warnings: WarningType[],
) {
    const hcpSchedules = filterSchedules(schedules, hcp.userId);

    const label = getAvailableHours(hcpSchedules, hcp.absent || false, warnings, hcp.userId);

    return {
        label: `${hcp.userName} ${bandLabel} - ${label}`,
        value: hcp.userId,
        isWorking: label !== WorkScheduleLabel.NOT_AT_WORK,
    };
}
