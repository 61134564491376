import Callout from '../../v2/components/Callout/Callout';
import { IconWarning } from '../../../helpers';

export const EnvironmentInfo = () => {
    let environmentName = process.env.REACT_APP_ENVIRONMENT?.toUpperCase();

    if (process.env.REACT_APP_DEV_BRANCH) {
        environmentName = `${environmentName} (DEV BRANCH ${process.env.REACT_APP_DEV_BRANCH})`;
    }

    return (
        <Callout intent="warning" rounded={false} Icon={IconWarning} testId="environment-info">
            This is a <strong>{environmentName}</strong> environment. Any actions performed here
            will not affect production systems.
        </Callout>
    );
};
