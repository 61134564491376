import { Callout } from '@blueprintjs/core';
import cn from 'classnames';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import WarningMessagesPanel from '../../../../v2/form/WarningMessagesPanel';
import PatientDataForm from '../../forms/PatientDataForm/PatientDetailsPdsLookup';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import Header from './children/Header';
import InfoPanelAlertsSection from './children/InfoPanelAlertsSection';
import InfoPanelJobDetailsSection from './children/InfoPanelJobDetailsSection';
import InfoPanelJobTimingsSection from './children/InfoPanelJobTimingsSection';
import InfoPanelPatientSection from './children/InfoPanelPatientSection';
import Loader from './children/Loader';
import { usePanelDetailsViewModel } from './usePanelDetailsViewModel';

interface IProps {
    refreshJobs(): void;
}

const PanelDetails: FC<IProps> = ({ refreshJobs }) => {
    const {
        RootStore: {
            ucrStore: { localPatientData, pdsData },
        },
    } = useStores<{ RootStore: RootStore }>();

    const {
        isAdminTime,
        isDoubleUp,
        isFirstUser,
        error,
        showDetailsAmendDialog,
        refetch,
        isLoading,
        setToFormPatientData,
        isStaffMemberEditable,
        loading,
        patient,
        onHcpReassignment,
        onClose,
    } = usePanelDetailsViewModel({
        refreshJobs,
    });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({ job: patient });

    return (
        <section className="visit-details">
            <Header
                patient={patient}
                refetch={refetch}
                isAdminTime={isAdminTime}
                isLoading={isLoading}
            />
            {error && <Callout intent="danger">{error.message}</Callout>}

            {loading ? (
                <Loader />
            ) : (
                <main
                    className={cn('visit-details__main', {
                        'visit-details__main--default': !isDoubleUp,
                        'visit-details__main--double-up': isDoubleUp,
                    })}
                >
                    <div className="visit-details__warning">
                        <InfoPanelAlertsSection />

                        <ConditionalDisplay show={hasWarningMessages}>
                            <div className="visit-details__warning">
                                <WarningMessagesPanel
                                    warnings={warningMessages}
                                    spacerTop={false}
                                />
                            </div>
                        </ConditionalDisplay>
                    </div>

                    <InfoPanelPatientSection job={patient} />

                    <InfoPanelJobDetailsSection
                        job={patient}
                        onHcpReassignment={onHcpReassignment}
                        isStaffMemberEditable={isStaffMemberEditable}
                        isFirstUser={isFirstUser}
                    />

                    <InfoPanelJobTimingsSection job={patient} isFirstUser={isFirstUser} />
                </main>
            )}
            {!error &&
                patient.nhsNumber &&
                !!localPatientData &&
                !!pdsData?.[patient.nhsNumber] && (
                    <Formik initialValues={patient} onSubmit={() => {}}>
                        <PatientDataForm
                            insideDialog
                            nhsNumber={patient.nhsNumber}
                            hasReferralData={false}
                            setToFormPatientData={setToFormPatientData}
                            showDialog={showDetailsAmendDialog}
                            onClose={onClose}
                        />
                    </Formik>
                )}
        </section>
    );
};

export default observer(PanelDetails);
