import { Schedule } from '@doc-abode/data-models';
import moment from 'moment';

export function getMaxSchedule(schedules: Schedule[]) {
    return schedules.length === 0 ? undefined : schedules[0];
}

export function getSortedHcpHours(hcpSchedules: Schedule[]) {
    const sortedSchedules = hcpSchedules?.sort((a, b) => {
        return moment(a.startDateTime).isAfter(moment(b.startDateTime)) ? 1 : -1;
    });

    return hcpSchedules?.length > 1 ? sortedSchedules : [...hcpSchedules];
}
