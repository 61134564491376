import { Callout, FormGroup, HTMLSelect, NumericInput, Radio, Tab, Tabs } from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import { DateInput3 } from '@blueprintjs/datetime2';
import { Hub, JobStatus, Vaccination } from '@doc-abode/data-models';
import moment from 'moment';
import { FC } from 'react';
import { IHcp } from '../../../../../interfaces';
import Error from '../../../../modules/forms/Error';
import Loader from '../../../../modules/helpers/Loader';
import { formatDisplayDate, parseDateStringToJSDate } from '../../../../modules/helpers/formatData';
import Modal from '../../../../modules/modal/Modal';
import { friendlyRouteTypes, routeTypes } from '../../utils';
// direct to specific files to avoid circular references.
// TODO i think this is the Itinerary Initialization error in development.
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Itinerary } from './Itinerary/Itinerary';
import { MapWrapper } from './MapWrapper';
import { PickupSummary } from './PickupSummary';
import { RouteDetailsAbortAlert } from './RouteDetailsAbortAlert';
import { RouteDetailsFooter } from './RouteDetailsFooter';
import { Warnings } from './Warnings';
import { useRouteDetailsViewModel } from './useRouteDetailsViewModel';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

interface Props {
    onClose: () => void;
    getAuthToken: () => Promise<string>;
    editJob: (data: Vaccination) => void;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
    setVaccinations: () => void;
    hubs: Hub[];
    jobId: string;
    users: IHcp[];
    allPatients: Vaccination[];
    select: (id: string) => void;
    deselectAll: () => void;
    apiKey: string;
    isSuperuser: boolean;
}

export const RouteDetails: FC<Props> = ({
    onClose,
    getAuthToken,
    editJob,
    refetchRoutes,
    setVaccinations,
    hubs,
    jobId,
    users,
    allPatients,
    select,
    deselectAll,
    apiKey,
    isSuperuser,
}) => {
    const {
        jobStatus,
        hub,
        instructions,
        routeDetails,
        loadingRoute,
        errorRoute,
        patientsOnRoute,
        title,
        modalRef,
        saving,
        expired,
        aborting,
        totalTravelTime,
        showPostJobNotes,
        editing,
        itineraryId,
        itinerary,
        isHcpUneditable,
        selectedCapacity,
        hubOptions,
        isCovidRoute,
        selectedUser,
        selectedEndTime,
        shifts,
        validationErrors,
        capacity,
        getUserHcpTypes,
        covidVaccinesOnRoute,
        daysSincePreviousDoseWarnings,
        selectedDate,
        showAbortPrompt,
        abortNotes,
        selectedDateTimeWarnings,
        changes,
        patientsOnRouteLoaded,
        selectedStartTime,
        patientsLength,
        totalDistanceDisplay,
        formatted,
        convertDateForStateSetter,
        onChangeHub,
        onChangeCapacity,
        setEditing,
        onAbort,
        onSave,
        setAbortNotes,
        setSelectedUser,
        setSelectedStartTime,
        setSelectedEndTime,
        setShowAbortPrompt,
        onResubmit,
    } = useRouteDetailsViewModel({
        getAuthToken,
        refetchRoutes,
        setVaccinations,
        hubs,
        jobId,
        select,
        deselectAll,
        isSuperuser,
    });

    if (loadingRoute) {
        return (
            <Modal
                testId="loading--container"
                title="Loading route details..."
                onClose={onClose}
                shadow
            />
        );
    }

    if (!routeDetails || errorRoute) {
        return <Modal testId="modal--error" title="Route not found" onClose={onClose} shadow />;
    }

    let footer = null;
    // setEditing check is needed to validate the other variables are valid since useRouteDetails has conditional returns.
    if (!saving && setEditing) {
        footer = (
            <RouteDetailsFooter
                setShowAbortPrompt={setShowAbortPrompt}
                setEditing={setEditing}
                aborting={aborting}
                jobStatus={jobStatus}
                editing={editing}
                onSave={onSave}
                hasChanges={changes.length > 0}
                hasErrors={validationErrors.length > 0}
                onResubmit={onResubmit}
                isSuperuser={isSuperuser}
            />
        );
    }

    return (
        <Modal
            title={title}
            onClose={onClose}
            forwardedRef={modalRef}
            footer={footer}
            shadow
            showPrintButton
        >
            {saving ? (
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                    <p className="create-routes__please-wait">Updating route...</p>
                </div>
            ) : !patientsOnRouteLoaded ? (
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                    <p className="create-routes__please-wait">Loading route details...</p>
                </div>
            ) : editing ? (
                <>
                    {daysSincePreviousDoseWarnings && daysSincePreviousDoseWarnings.length > 0 && (
                        <Callout intent="danger" className="create-routes__callout">
                            The following patients have validation warnings, please see below for
                            details.
                            <Warnings warnings={daysSincePreviousDoseWarnings} />
                        </Callout>
                    )}
                    <h3 className="h3">Route options</h3>
                    <div className="create-routes__select-date-time">
                        <div className="create-routes__date-time-controls">
                            <FormGroup label="Date" labelFor="date">
                                <DateInput3
                                    locale={navigator.languages[0]}
                                    showTimezoneSelect={false}
                                    inputProps={{ id: 'date' }}
                                    formatDate={formatDisplayDate}
                                    parseDate={parseDateStringToJSDate}
                                    onChange={convertDateForStateSetter}
                                    value={selectedDate?.toISOString() || null}
                                    maxDate={moment().add(1, 'year').toDate()}
                                />
                            </FormGroup>
                            <FormGroup label="Start time">
                                <TimePicker
                                    onChange={setSelectedStartTime}
                                    value={selectedStartTime}
                                    selectAllOnFocus
                                />
                            </FormGroup>
                            <FormGroup label="End time">
                                <TimePicker
                                    onChange={setSelectedEndTime}
                                    value={selectedEndTime}
                                    selectAllOnFocus
                                />
                            </FormGroup>
                            {isCovidRoute && (
                                <FormGroup label="Max. doses *">
                                    <NumericInput
                                        className="create-routes__numeric-input"
                                        onValueChange={onChangeCapacity}
                                        value={selectedCapacity}
                                        min={covidVaccinesOnRoute.length}
                                        selectAllOnFocus
                                        large
                                    />
                                </FormGroup>
                            )}
                            <FormGroup label="Start/end location">
                                <HTMLSelect
                                    onChange={onChangeHub}
                                    value={hub?.id}
                                    large
                                    options={hubOptions}
                                />
                            </FormGroup>
                        </div>
                        <Error errors={validationErrors} />
                    </div>
                    <h3 className="h3">Assigned HCP</h3>
                    <table className="bp5-html-table users-table bp5-interactive">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>User ID</th>
                                <th>HCP Type(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users
                                .filter((user) => user.enabled && user.roles?.includes('hcp'))
                                .map(({ userId, userName, hcpTypes }) => {
                                    const selected = selectedUser === userId;
                                    const onSelect = () => setSelectedUser(userId);

                                    const formattedHcpTypes = getUserHcpTypes(userId);

                                    return (
                                        <tr
                                            key={userId}
                                            data-testid={'users-table-row'}
                                            className={`users-table__row ${
                                                selected ? 'users-table__row--selected' : ''
                                            }`}
                                            onClick={() => {
                                                if (!isHcpUneditable) {
                                                    onSelect();
                                                }
                                            }}
                                        >
                                            <td>
                                                <Radio
                                                    id={userId}
                                                    checked={selected}
                                                    onChange={onSelect}
                                                    disabled={isHcpUneditable}
                                                />
                                            </td>
                                            <td>{userName}</td>
                                            <td>{userId}</td>
                                            <td>
                                                {formattedHcpTypes && formattedHcpTypes.length > 0
                                                    ? formattedHcpTypes.join(', ')
                                                    : '-'}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    {selectedDateTimeWarnings.length > 0 && (
                        <Callout intent="warning" className="create-routes__callout">
                            {selectedDateTimeWarnings.map((warning) => (
                                <li key={warning}>{warning}</li>
                            ))}
                        </Callout>
                    )}
                </>
            ) : (
                <>
                    <dl className="info">
                        <dt className="info__title">Route type</dt>
                        <dd className="info__definition">
                            {
                                friendlyRouteTypes[
                                    routeDetails.routeType as keyof typeof friendlyRouteTypes
                                ]
                            }
                        </dd>
                        <dt className="info__title">Number of patients</dt>
                        <dd className="info__definition">{patientsLength}</dd>
                        {routeDetails.routeType === routeTypes.COVID && (
                            <>
                                <dt className="info__title">Max. doses</dt>
                                {capacity && capacity.length > 0 && (
                                    <dd className="info__definition">{capacity[0]}</dd>
                                )}
                            </>
                        )}
                        <dt className="info__title">Shift start/end time</dt>
                        {shifts && (
                            <dd className="info__definition">{formatted?.shiftStartAndEndTime}</dd>
                        )}
                        <dt className="info__title">Route start/end time</dt>
                        <dd className="info__definition">
                            {formatted?.routeStartAndEndTime} (estimated)
                        </dd>
                        <dt className="info__title">Total travel distance</dt>
                        <dd className="info__definition">{totalDistanceDisplay}</dd>
                        <dt className="info__title">Total travel duration</dt>
                        <dd className="info__definition">{totalTravelTime}</dd>
                        <ConditionalDisplay show={showPostJobNotes ? true : false}>
                            <dt className="info__title">Post-job notes</dt>
                            <dd className="info__definition info__definition--notes">
                                {routeDetails.postVisitNotes}
                            </dd>
                        </ConditionalDisplay>
                        <dt className="info__title">Created on</dt>
                        <dd className="info__definition">{formatted?.createDateTime}</dd>
                        <dt className="info__title">Created by</dt>
                        <dd className="info__definition">{routeDetails.createdBy}</dd>
                        <dt className="info__title">Last updated by</dt>
                        <dd className="info__definition">{routeDetails.lastUpdatedBy}</dd>
                        {jobStatus === JobStatus.AVAILABLE && (
                            <>
                                <dt
                                    className={`info__title ${
                                        expired ? 'info__title--danger' : ''
                                    }`}
                                >
                                    Expiry
                                </dt>
                                <dd
                                    className={`info__definition ${
                                        expired ? 'info__title--danger' : ''
                                    }`}
                                >
                                    {formatted?.expiryDateTime}
                                </dd>
                            </>
                        )}
                        {jobStatus === JobStatus.HCP_ABORTED && (
                            <>
                                <dt className="info__title info__title--danger">
                                    Reason for aborting
                                </dt>
                                <dd className="info__definition info__definition--danger">
                                    {routeDetails.hcpAbortedReason}
                                    <br />
                                    {routeDetails.hcpAbortedNotes}
                                </dd>
                            </>
                        )}
                        {jobStatus === JobStatus.CONTROLLER_ABORTED &&
                            routeDetails.controllerAbortedNotes && (
                                <>
                                    <dt className="info__title info__title--danger">
                                        Reason for aborting
                                    </dt>
                                    <dd className="info__definition info__definition--danger">
                                        {routeDetails.controllerAbortedNotes}
                                    </dd>
                                </>
                            )}
                    </dl>
                    <div className="bp5-callout route-details__pickup-summary-container">
                        {patientsOnRoute && (
                            <PickupSummary
                                vaccinationRoute={routeDetails}
                                patientsOnRoute={patientsOnRoute}
                            />
                        )}
                    </div>
                    <Tabs
                        id="RouteDetails"
                        defaultSelectedTabId="itinerary"
                        renderActiveTabPanelOnly
                        large
                    >
                        {instructions && (
                            <Tab
                                id="itinerary"
                                title="Itinerary"
                                panel={
                                    <Itinerary
                                        hub={hub}
                                        instructions={instructions}
                                        routeItineraryId={itineraryId}
                                        routeStatus={jobStatus}
                                        modalRef={modalRef}
                                    />
                                }
                            />
                        )}
                        <Tab
                            id="map"
                            title="Map"
                            panel={<MapWrapper itineraries={[itinerary ?? {}]} apiKey={apiKey} />}
                        />
                    </Tabs>
                </>
            )}
            <RouteDetailsAbortAlert
                setAbortNotes={setAbortNotes}
                onAbort={onAbort}
                setShowAbortPrompt={setShowAbortPrompt}
                showAbortPrompt={showAbortPrompt}
                abortNotes={abortNotes}
            />
        </Modal>
    );
};
