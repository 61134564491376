import { Alert, Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import { ConditionalDisplay } from '../../CondtionalDisplay';
import { Callout } from '../../v2/components';
import { ConnectedClientsTable } from './components/ConnectedClientsTable';
import { S1ManagementViewModel } from './S1ManagementViewModel';

const S1Management = () => {
    const {
        confirmLocks,
        confirmQueue,
        handleSelectQueue,
        handleSelectLocks,
        handlePurgeQueue,
        handlePurgeLocks,
    } = S1ManagementViewModel();

    const {
        RootStore: {
            userStore: { isSuperuser },
        },
    } = useStores<{ RootStore: RootStore }>();

    return (
        <div className="page">
            <h1 className="h1">SystmOne</h1>

            <div>
                <ConditionalDisplay show={!isSuperuser}>
                    <Callout intent="warning" spacerBottom={true}>
                        You need to be a superuser to be able to purge queues or locks. Superuser
                        rights can be granted by Administrators via the "Manage users" screens.
                    </Callout>
                </ConditionalDisplay>

                <Callout intent="warning" spacerBottom={true}>
                    Before purging the message queue or job locks, please ensure all running Doc
                    Abode SystmOne Integration clients are disconnected.
                </Callout>
            </div>

            <div className="flex">
                <p className="font-bold">Would you like to purge the message queue?</p>
                <Button
                    disabled={!isSuperuser}
                    intent="danger"
                    large
                    type="button"
                    onClick={() => handleSelectQueue()}
                >
                    Purge Queue
                </Button>
            </div>
            <br />
            <div className="flex">
                <p className="font-bold">Would you like to purge the job locks?</p>
                <Button
                    disabled={!isSuperuser}
                    intent="danger"
                    large
                    type="button"
                    onClick={() => handleSelectLocks()}
                >
                    Purge Locks
                </Button>
            </div>

            <Alert
                isOpen={confirmQueue}
                onConfirm={() => handlePurgeQueue()}
                onCancel={() => handleSelectQueue()}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                icon="warning-sign"
                className="dialog--wide"
                intent="danger"
            >
                <h2 className="h2">Important Note</h2>
                <p>Are you sure you want to purge the queue?</p>
            </Alert>

            <Alert
                isOpen={confirmLocks}
                onConfirm={() => handlePurgeLocks()}
                onCancel={() => handleSelectLocks()}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                icon="warning-sign"
                className="dialog--wide"
                intent="danger"
            >
                <h2 className="h2">Important Note</h2>
                <p>Are you sure you want to purge the locks?</p>
            </Alert>
            <br />
            <ConnectedClientsTable />
        </div>
    );
};

export default observer(S1Management);
