import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';

export const useUsersForRoleViewModel = () => {
    const {
        RootStore: {
            userStore: { isAdmin },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    return {
        // As an Admin we can view all users, as a normal Controller we only see "live" users
        users: isAdmin ? users : users.filter((user: any) => user.enabled),
    };
};
