import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import {
    addUser,
    getHcpFunctions,
    getUsers,
    resetPassword,
    setHcpFunction,
    updateUser,
    updateUserAbsence,
    updateUserFromController,
} from '../api/usersApi';
import { getHcp } from '../helpers/ucr/getHcp';
import { IHcp } from '../interfaces';
import RootStore from './RootStore';
interface FiltersType {
    [key: string]: string[];
}

interface HcpFunction {
    hcpDayResponsibility: string[];
    userId: string;
}

class UsersStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            users: observable,
            loaded: observable,
            filters: observable,
            selected: observable,
            loading: observable,
            allUsers: computed,
            selectedUsers: computed,
            hcpUsers: computed,
            getUsers: action,
            setUserStatus: action,
            startTimer: action,
            dispose: action,
            addUser: action,
            setFilters: action,
            focusedHcp: observable,
            hcpFunctions: observable,
        });

        this.rootStore = rootStore;
    }

    users: IHcp[] = [];
    loaded = false;
    loading = false;
    filters: FiltersType = {};
    selected: string[] = [];
    focusedHcp: string | null = null;
    hcpFunctions: HcpFunction[] = [];
    loadingFunctions = false;
    fetchInterval: ReturnType<typeof setInterval> | null = null;

    select = (userId: string) => {
        if (!this.selected.includes(userId)) {
            this.selected.push(userId);
        }
    };

    deselect = (userId: string) => {
        const index = this.selected.indexOf(userId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    get allUsers() {
        return this.users.map((user) => ({
            ...user,
            selected: this.selected.includes(user.userId),
        }));
    }

    get selectedUsers() {
        return this.allUsers.filter((user) => this.selected.includes(user.userId));
    }

    get hcpUsers() {
        return this.users.filter((user) => {
            return user.roles?.includes('hcp');
        });
    }

    get filteredUsers() {
        let users = [...this.hcpUsers];
        Object.entries(this.filters).forEach(([name, values]) => {
            if (name === 'hcpType') {
                users = users.filter((user) =>
                    user.hcpTypes.some((hcpType) => values.includes(hcpType)),
                );
            }
        });
        return users;
    }

    setFilters = (filters: FiltersType) => {
        this.filters = filters;
    };

    getEnabledUsers(users: IHcp[]) {
        return users.filter((user) => user.enabled);
    }

    setUsers = (users: IHcp[]) => {
        this.users = users;
    };

    getUsers = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return this.dispose();
        }

        const response = await getUsers(userSession.tokens.id);

        if (response.status === 200) {
            const { users }: { users: IHcp[] } = await response.json();
            this.users = users.map((user) => ({
                ...user,
                available: user.available ?? false,
                firstName: user.userName.split(' ').slice(0, -1).join(' '),
                lastName: user.userName.split(' ').slice(-1).join(' '),
            }));

            this.loaded = true;
            this.rootStore.lovsStore.setLov(
                'nominatedHcps',
                this.hcpUsers.map((user) => ({
                    label: `${user.userName} (${user.userId})`,
                    value: user.userId,
                    hcpTypes: user.hcpTypes,
                })),
            );
        }
    };

    setUserStatus = async (userId: string, enabled: boolean) => {
        try {
            const userData = { enabled };
            const userSession = await this.rootStore.userStore.getUserSession();

            if (!userSession) {
                return { successful: false };
            }

            const response = await updateUser(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating user');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    updateUserData = async (userId: string, userData: string | { [key: string]: any }) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                return { successful: false };
            }

            const response = await updateUserFromController(
                userId,
                userData,
                userSession.tokens.id,
            );

            if (response.status === 200) {
                let data = await response.json();

                const user = getHcp(this.users, data.userId);
                if (user?.staffPins) {
                    user.staffPins = data.staffPins;
                }
            }

            if (response.status !== 200) {
                throw new Error('Error updating user');
            }

            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    resetPassword = async (userId: string) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                return { successful: false };
            }

            const response = await resetPassword(userId, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error resetting password');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getUsers();
            this.fetchInterval = setInterval(() => {
                this.getUsers();
                this.getHcpFunctions(
                    moment(this.rootStore.ucrStore.selectedDate).format('YYYY-MM-DD'),
                );
            }, 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    addUser = async (userData: string | { [key: string]: any }) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                return { response: { status: 401 } };
            }

            const response = await addUser(userData, userSession.tokens.id);
            const body = await response.json();
            await this.getUsers();
            return { response, body };
        } catch (err) {
            console.error(err);
            return { response: { status: 500 } };
        }
    };

    setUserDemographics = async (
        userId: string,
        demographicsData: { [key: string]: string | number },
    ) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                return { successful: false };
            }

            const userData = {
                band: demographicsData.band,
                enabled: demographicsData.enabled,
                gender: demographicsData.gender,
                phoneNumber: demographicsData.phoneNumber,
                email: demographicsData.email,
                organisation: {
                    name: this.rootStore.configStore.org,
                    data: demographicsData.orgData,
                },
                rolesData: demographicsData.rolesData,
                s1Username: demographicsData.s1Username,
            };

            const response = await updateUser(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating user');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    setUserAbsence = async (userId: string, absent: boolean) => {
        try {
            const userData = { absent };
            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                return { successful: false };
            }

            const response = await updateUserAbsence(userId, userData, userSession.tokens.id);
            if (response.status !== 200) {
                throw new Error('Error updating absence of user');
            }
            await this.getUsers();
            return { successful: true };
        } catch (err) {
            console.error(err);
            return { successful: false };
        }
    };

    getHcpFunctions = async (date: string) => {
        this.loadingFunctions = true;
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                throw new Error('Error getting user session');
            }

            const response = await getHcpFunctions(date, userSession.tokens.id);
            if (response.status === 401 || response.status === 403) {
                throw new Error('Error: Unauthorised.');
            }
            if (response.status !== 200) {
                throw new Error('Error getting hcp functions');
            }
            this.hcpFunctions = (await response.json()).functions;
            this.loadingFunctions = false;
        } catch (err) {
            const error = err as Error;

            // only log error if its not session related or a 401/403.
            if (
                error.message !== 'Error getting user session' &&
                error.message !== 'Error: Unauthorised.'
            ) {
                console.error(error);
            }

            this.loadingFunctions = false;
        }
    };

    setHcpFunction = async (userId: string, hcpDayResponsibility: string[], date: string) => {
        try {
            const userData = { hcpDayResponsibility, date };
            this.focusedHcp = userId;

            this.loading = true;

            const userSession = await this.rootStore.userStore.getUserSession();
            if (!userSession) {
                throw new Error('Error getting user session');
            }

            const response = await setHcpFunction(userId, userData, userSession.tokens.id);
            if (response.status === 401 || response.status === 403) {
                throw new Error('Error: Unauthorised.');
            }
            if (response.status !== 200) {
                throw new Error('Error updating hcp function');
            }
            if (!this.loadingFunctions) {
                await this.getHcpFunctions(
                    moment(this.rootStore.ucrStore.selectedDate).format('YYYY-MM-DD'),
                );
            }
            this.loading = false;
            this.focusedHcp = null;

            return { successful: true };
        } catch (err) {
            const error = err as Error;

            // only log error if its not session related or a 401/403.
            if (
                error.message !== 'Error getting user session' &&
                error.message !== 'Error: Unauthorised.'
            ) {
                console.error(error);
            }

            this.loading = false;
            this.focusedHcp = null;

            return { successful: false };
        }
    };

    getUserHcpTypes = (userId: string) => {
        const user = getHcp(this.users, userId);
        const { hcpType } = this.rootStore.lovsStore;
        return hcpType
            .filter((hcpType) => {
                if (!user?.hcpTypes) return false;
                return user.hcpTypes.includes(hcpType.value);
            })
            .map((hcpType) => hcpType.label);
    };

    getUserBand = (userId: string) => {
        const user = getHcp(this.users, userId);
        const { bands } = this.rootStore.configStore;
        if (bands && user?.band) {
            const userBand = bands.find((band) => user.band === band.value);
            if (userBand) {
                return userBand.label;
            } else {
                return null;
            }
        }
        return null;
    };
}

export default UsersStore;
