import { WarningType } from '../../interfaces/ucr';

export function filterWarningsByCategory(warnings?: WarningType[], category?: string) {
    if (!warnings) {
        return [];
    }

    if (!category) {
        return warnings;
    }

    return warnings.filter((warning) => warning.category === category);
}

export function filterWarningsByHcp(warnings?: WarningType[], userId?: string) {
    if (!warnings) {
        return [];
    }

    if (!userId) {
        return warnings;
    }

    return warnings.filter((warning) => warning.data?.hcpId === userId);
}

type IFilterWarnings = {
    warnings?: WarningType[];
    category?: string;
    userId?: string;
};

export function filterWarnings({ warnings, category, userId }: IFilterWarnings) {
    if (!warnings) {
        return [];
    }

    return warnings.filter((warning) => {
        if (category && warning.category !== category) {
            return false;
        }
        if (userId && warning.data?.hcpId !== userId) {
            return false;
        }

        return true;
    });
}
