import { S1ClientConnectionStatus } from '@doc-abode/data-models';
import moment from 'moment';
import { FC } from 'react';
import { useConnectedClientRowViewModel } from './useConnectedClientRowViewModel';

const DisplayDate: FC<{ date: string | undefined }> = ({ date }) => {
    if (!date) return null;

    return <>{moment(date).format('Do MMM YYYY HH:mm:ss')}</>;
};

const ConnectedClientRow: FC<{
    clientConnectionStatus: S1ClientConnectionStatus;
    index: number;
}> = ({ clientConnectionStatus, index }) => {
    const { userName, clientVersion, lastActivity, status, statusClassName } =
        useConnectedClientRowViewModel(clientConnectionStatus);
    return (
        <tr
            tabIndex={index}
            key={'s1-connected-clients-row__' + index}
            className="s1-connected-clients__containerWidth s1-connected-clients__list-row s1-connected-clients__list-row--content"
        >
            <td>{userName}</td>
            <td className="whitespace__nowrap">{clientVersion}</td>
            <td>
                <DisplayDate date={lastActivity} />
            </td>
            <td className="s1-connected-clients__status-column">
                <span className={statusClassName}>{status}</span>
            </td>
        </tr>
    );
};

export default ConnectedClientRow;
