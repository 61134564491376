import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import { when } from 'mobx';
import { useCallback, useEffect } from 'react';
import { RoutesProps } from './Routes';
import { useQuery } from '@apollo/client';
import { QUERY_JOBS_BY_JOB_TYPE } from '../../../graphql/queries/jobs';
import { JobType } from '@doc-abode/data-models';
import moment from 'moment';
import { graphqlErrorHandler } from '../common/errorHandling/graphqlErrorHandler';

const POLL_INTERVAL = 60000;

export const useRoutesModel = ({ location, history, routeMatch }: RoutesProps) => {
    const {
        RootStore: {
            routesStore: {
                setPage,
                pageCount,
                setSortFn,
                pagedJobs,
                pageStart,
                pageEnd,
                page: routesPage,
                filters,
                setFilters,
                totalJobsNumber,
                setJobs,
                editJob,
                showAbortedByController,
                toggleShowAbortedByController,
                runFilterAdapters,
            },
            usersStore: { hcpUsers, getUserHcpTypes },
            userStore: { getUserSession, isSuperuser, getAuthToken },
            configStore: {
                hubs,
                clientKeys,
                vaccinationDuration,
                vaccinationDetails,
                doseInterval,
            },
            lovsStore: { hcpType },
        },
    } = useStores<{ RootStore: RootStore }>();

    const yearAgo = moment().subtract(1, 'year').startOf('day').toISOString();
    const selectedFilters = runFilterAdapters();
    let createDateTimeFilter = {
        createDateTime: {
            ge: yearAgo,
        },
    };

    let filter: { [key: string]: any } = {};
    if (selectedFilters['jobStatus']) {
        const jobStatusFilter = selectedFilters['jobStatus'].map((status) => {
            return { jobStatus: { eq: status } };
        });

        const logicJobStatusFilter = {
            or: jobStatusFilter,
        };

        filter = { and: [createDateTimeFilter, logicJobStatusFilter] };
    } else {
        filter = createDateTimeFilter;
    }

    const { data, loading, refetch } = useQuery(QUERY_JOBS_BY_JOB_TYPE, {
        variables: {
            jobType: JobType.ROUTE,
            filter,
            itineraryType: 'full',
        },
        fetchPolicy: 'network-only',
        pollInterval: POLL_INTERVAL,
        onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
    });

    const { search, pathname } = location;
    const { replace } = history;

    const showUpdateRoute =
        pathname.endsWith('/add-to-existing') ||
        pathname.endsWith('/withdraw-from-existing') ||
        pathname.endsWith('/recalculate');

    const showCreateRoutes = pathname.endsWith('/new');
    const jobId = routeMatch?.params.jobId;
    const showRouteDetails = jobId ? !pathname.endsWith('/recalculate') : false;

    const query = new URLSearchParams(search);
    const page = query.get('page');

    const setUrlPage = useCallback(
        (page: number) => {
            replace(`${pathname}?page=${page + 1}`);
            setPage(page);
        },
        [pathname, replace, setPage],
    );

    useEffect(() => {
        if (!loading && data) {
            const routes = data.queryJobsByJobTypeIndex.items;
            setJobs(routes);
        }
    }, [data, loading, setJobs]);

    useEffect(() => {
        if (page) {
            const pageNumber = parseInt(page, 10) - 1;

            if (pageNumber < 0) {
                setUrlPage(0);
                return;
            }

            if (!loading && pageNumber >= pageCount) {
                setUrlPage(pageCount - 1);
                return;
            }

            setPage(pageNumber);

            when(
                () => loading === false,
                () => {
                    if (pageNumber >= pageCount) {
                        setUrlPage(pageCount - 1);
                    }
                },
            );
        }
    }, [loading, page, pageCount, setPage, setUrlPage]);

    const handleShowRouteDetails = (id: string) => {
        history.push(`/vaccinations/routes/${id}`);
    };

    const handleCloseModal = () => {
        if (location.state && location.state.closeModal) {
            return history.goBack();
        }

        history.push('/vaccinations/routes');
    };

    const handleSort = (sortFunc: any) => {
        setSortFn(sortFunc);
    };

    return {
        loading,
        pagedJobs,
        pageStart,
        pageEnd,
        pageCount,
        routesPage,
        filters,
        setFilters,
        totalJobsNumber,
        setJobs,
        editJob,
        showAbortedByController,
        toggleShowAbortedByController,
        hcpUsers,
        getUserHcpTypes,
        getUserSession,
        isSuperuser,
        getAuthToken,
        hubs,
        clientKeys,
        vaccinationDuration,
        vaccinationDetails,
        doseInterval,
        hcpType,
        showUpdateRoute,
        showCreateRoutes,
        showRouteDetails,
        handleShowRouteDetails,
        handleCloseModal,
        handleSort,
        setUrlPage,
        pathname,
        jobId,
        refetch,
    };
};
