import { FallbackRender } from '@sentry/react';

const AppShellErrorBoundary: FallbackRender = () => {
    return (
        <div className="page">
            <p>
                Sorry, an error occurred and we could not load the application. Please reload the
                browser window and if the error persists please contact{' '}
                <a href="mailto:support@docabode.com">support@docabode.com</a>.
            </p>
        </div>
    );
};

export default AppShellErrorBoundary;
