import UCR from './UCR';
import Vaccinations from './Vaccinations';

import AddUser from '../../modules/users/add-user/AddUser';
import AdminManageInvites from '../../pages/admin/AdminManageInvites';
import Onboarding from '../../pages/admin/Onboarding';
import S1Management from '../../pages/s1/S1Management';
import UsersList from '../../pages/users/UsersList';

export type RouteInformation = {
    path: string;
    tabTarget?: string;
    tabIcon?: any;
    tabId?: string;
    tabLabel?: string;
    component: any;
    exact?: boolean;
    userHasAccess: boolean;
    isEnabled: boolean;
};

export function userHasGroup(groups: boolean[]) {
    return groups.some((group) => group === true);
}

export function userHasAccess(permittedGroups: boolean[], deniedGroups?: boolean[]) {
    if (deniedGroups && userHasGroup(deniedGroups)) {
        return false;
    }
    return userHasGroup(permittedGroups);
}

export function getRoutes(
    isController: boolean,
    isAdmin: boolean,
    isSuperuser: boolean,
    isComplianceOfficer: boolean,
    isFeatureEnabled: (feature: string) => boolean,
): RouteInformation[] {
    const routes: RouteInformation[] = [
        {
            path: '/scheduling',
            tabIcon: 'flash',
            tabLabel: 'Scheduling',
            component: UCR,
            userHasAccess: userHasAccess([isController]),
            isEnabled: isFeatureEnabled('ucr'),
        },
        {
            path: '/vaccinations',
            tabIcon: 'shield',
            tabLabel: 'Vaccinations',
            component: Vaccinations,
            userHasAccess: userHasAccess([isController]),
            isEnabled: isFeatureEnabled('vaccinations'),
        },
        {
            path: '/users/:userId?',
            tabTarget: '/users',
            tabIcon: 'people',
            tabLabel: isAdmin ? 'Manage Users' : 'HCPs',
            component: UsersList,
            exact: true,
            userHasAccess: userHasAccess([isController, isAdmin], [isComplianceOfficer]),
            isEnabled: true,
        },
        {
            path: '/add-user',
            tabIcon: 'new-person',
            tabLabel: 'Add User',
            component: AddUser,
            exact: true,
            userHasAccess: userHasAccess([isAdmin]),
            isEnabled: isFeatureEnabled('addUser'),
        },
        {
            path: '/manage-applications/:inviteId?',
            tabTarget: '/manage-applications',
            tabIcon: 'clipboard',
            tabLabel: 'Manage Applications',
            component: AdminManageInvites,
            exact: true,
            userHasAccess: userHasAccess([isAdmin]),
            isEnabled: isFeatureEnabled('invites'),
        },
        {
            path: '/organisation-details',
            tabIcon: 'office',
            tabLabel: 'Organisation',
            component: Onboarding,
            exact: true,
            userHasAccess: userHasAccess([isAdmin], [isComplianceOfficer]),
            isEnabled: isFeatureEnabled('invites'),
        },
        {
            path: '/systmone',
            tabId: 'systmone',
            tabIcon: 'cloud-server',
            tabLabel: 'SystmOne',
            component: S1Management,
            exact: true,
            userHasAccess: userHasAccess([isController, isAdmin]),
            isEnabled: isFeatureEnabled('s1Enabled'),
        },
    ] as RouteInformation[];

    return routes.filter((route: RouteInformation) => route.userHasAccess && route.isEnabled);
}
