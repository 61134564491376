import { sortBy } from 'lodash';
import { observer } from 'mobx-react';

import { IconWarning } from '../../../helpers/ucr/icons';
import { WarningType } from '../../../interfaces/ucr';
import { Callout, calloutIntent } from '../components/Callout';

type WarningMessagesPanelProps = {
    warnings?: WarningType[];
    spacerTop?: boolean;
    intent?: calloutIntent;
    compact?: boolean;
};

const WarningMessagesPanel = ({
    warnings = [],
    spacerTop = true,
    intent = 'warning',
    compact = true,
}: WarningMessagesPanelProps) => {
    if (!warnings.length) {
        return <></>;
    }

    return (
        <Callout intent={intent} Icon={IconWarning} spacerTop={spacerTop} compact={compact}>
            {sortBy(warnings, ['message']).map(({ message, category, data }: WarningType, i) => (
                <p className="warning-block" data-testid={category} key={i}>
                    {message}
                </p>
            ))}
        </Callout>
    );
};

export default observer(WarningMessagesPanel);
