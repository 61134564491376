import React, { FC, useEffect, useState } from 'react';
import md5 from 'md5';

import { Instruction as InstructionType, JobType, Vaccination } from '@doc-abode/data-models';
import * as util from 'util';
import useStores from '../../../../../../hook/useStores';
import { Instruction } from './Instruction';
import {
    getNameOfPlaceWeTravelToNextIfItsAPickup,
    nextLocationIsSameAsPreviousLocation,
    getVaccinationOdsCodesNeedingPickup,
    getVaccinationIdToUse,
    getVaccinationJobStatusTag,
    shouldStrikeThroughTheInstruction,
} from './itineraryHelpers';
import { IInstructionProps } from './ItineraryTypes';
import RootStore from '../../../../../../stores/RootStore';
import { useLazyQuery } from '@apollo/client';
import { QUERY_JOBS_BY_JOB_TYPE } from '../../../../../../graphql/queries/jobs';
import { graphqlErrorHandler } from '../../../../common/errorHandling/graphqlErrorHandler';

interface Props {
    instructions: InstructionType[];
    hub?: { address: string };
    newItems?: string[];
    openInNewWindow?: boolean;
    routeItineraryId?: string;
    routeStatus?: string; // should this type be JobStatus? If it is fix itineraryHelpers.IInstructionJobStatusCountsAsRemoved
    modalRef?: any;
}

export const Itinerary: FC<Props> = ({
    instructions,
    hub,
    newItems = [],
    openInNewWindow = false,
    routeItineraryId = '',
    routeStatus = '',
    modalRef,
}) => {
    const [vaccinationsOnRoute, setVaccinationsOnRoute] = useState<Vaccination[]>([]);
    const [vaccinationsOnRouteLoaded, setVaccinationsOnRouteLoaded] = useState<boolean>(false);
    const {
        RootStore: { configStore },
    } = useStores<{ RootStore: RootStore }>();

    const vaccinationDetailsForOrganisation = configStore.vaccinationDetails;
    /**
     * We need to match-up the up-to-date Vaccination we get from the api with its instruction
     * This should loop the instructions prop and update the allPatients in state.
     * This should then re-render the component when we have the latest info, allowing us to
     * make calculate which pickups are no longer required.
     */

    const [fetchPatients] = useLazyQuery(QUERY_JOBS_BY_JOB_TYPE, {
        fetchPolicy: 'network-only',
        onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
    });

    useEffect(() => {
        const getPatients = async (vaccinationIds: string[]) => {
            const patientFilter = vaccinationIds.map((id) => {
                return { id: { eq: id } };
            });

            try {
                const response = await fetchPatients({
                    variables: {
                        jobType: JobType.VACCINATION,
                        filter: { or: patientFilter },
                    },
                });

                const patients = response.data.queryJobsByJobTypeIndex.items as Vaccination[];
                setVaccinationsOnRoute(patients);
                setVaccinationsOnRouteLoaded(true);
            } catch (err) {
                // error from graphql is presented to user through the app toaster.
            }
        };

        let vaccinationIds: string[] = [];
        for (const instruction of instructions) {
            const instructionType = instruction.instructionType;
            // id is Instruction.itineraryItem.name
            // which can match to a Vaccination.id
            if (instructionType === 'VisitLocation') {
                const jobId = instruction.itineraryItem?.name;
                if (jobId) {
                    vaccinationIds.push(jobId);
                }
            }
        }

        getPatients(vaccinationIds);
    }, [fetchPatients, instructions, setVaccinationsOnRoute, setVaccinationsOnRouteLoaded]);
    const mappedInstructions: IInstructionProps[] = [];
    const odsCodesNeedingPickup = getVaccinationOdsCodesNeedingPickup({
        routeStatus,
        routeItineraryId,
        vaccinations: vaccinationsOnRoute,
        vaccinationDetailsForOrganisation,
    });
    instructions.forEach((instruction: InstructionType, index: number) => {
        const nextInstruction = instructions[index + 1];
        // If we are staying in the same place there is no need for an instruction.
        if (
            nextLocationIsSameAsPreviousLocation({
                currentInstruction: instruction,
                previousInstruction: instructions[index - 1],
                nextInstruction,
            })
        ) {
            return;
        }
        let vaccinationId = getVaccinationIdToUse({ instruction, nextInstruction });
        const vaccination: Vaccination | undefined | null = vaccinationsOnRoute.find(
            (patient) => patient.id === vaccinationId,
        );
        const nameTravelToNext = getNameOfPlaceWeTravelToNextIfItsAPickup(nextInstruction);
        const jobStatusTag = getVaccinationJobStatusTag({
            routeItineraryId,
            vaccination,
            routeStatus,
        });

        const strikeThrough = shouldStrikeThroughTheInstruction({
            instruction,
            odsCodesNeedingPickup,
            routeItineraryId,
            vaccination,
            vaccinationsOnRouteLoaded,
        });

        mappedInstructions.push({
            ...instruction,
            strikeThrough,
            hub,
            nameTravelToNext,
            newItem: newItems.includes(instruction.itineraryItem?.name as string),
            vaccination,
            openInNewWindow,
            jobStatusTag,
            modalRef,
        });
    });
    return (
        <table className="bp5-html-table" data-testid="itinerary-table">
            <thead>
                <tr>
                    <th>Start time</th>
                    <th>End time</th>
                    <th>Instruction</th>
                </tr>
            </thead>
            <tbody>
                {mappedInstructions.map((mappedInstruction, index) => {
                    // hashing the mappedInstruction was the only thing I could think of that would have
                    // a unique and consistent key.   instructions have no id,  itineraryItem.name is not unique
                    // various values e.g. vaccination can be undefined so we cant use vaccination.id.
                    return (
                        <Instruction
                            {...mappedInstruction}
                            key={`instruction-${md5(util.inspect(mappedInstruction))}`}
                        />
                    );
                })}
            </tbody>
        </table>
    );
};
