import { PatientAlert } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import getHcpName from '../../../../../../helpers/ucr/getHcpName';
import { IconWarning } from '../../../../../../helpers/ucr/icons';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import Button from '../../../../../v2/components/Button/Button';
import Callout from '../../../../../v2/components/Callout/Callout';
import { useInfoPanelAlertsSectionViewModel } from './useInfoPanelAlertsSectionViewModel';

/**
 * Displays warnings and alerts relating to the patient visit.
 */
const InfoPanelAlertsSection: FC = () => {
    const {
        setOpenedPatientAlertResolver,
        onViewDetailsClick,
        users,
        showWarnings,
        unresolved,
        hasUnresolvedAlerts,
        resolved,
        hasResolvedAlerts,
    } = useInfoPanelAlertsSectionViewModel();

    if (!showWarnings) {
        return null;
    }

    return (
        <>
            <ConditionalDisplay show={hasUnresolvedAlerts}>
                <div className="visit-details__warning">
                    <Callout intent="danger" Icon={IconWarning} spacerTop={false}>
                        {unresolved.map((alert: PatientAlert, i: number) => (
                            <div
                                key={`visit-details__patient-unresolved-alert-${alert.alertId}`}
                                className="visit-details-warnings__outer"
                            >
                                <div className="visit-details-warnings__button">
                                    <div className="visit-details-warnings__button-inner">
                                        <Button
                                            name="Resolve Patient Alert"
                                            clickEvent={() => setOpenedPatientAlertResolver(alert)}
                                        />
                                    </div>
                                </div>
                                <span
                                    className="visit-details-warnings__view-details"
                                    onClick={() => onViewDetailsClick(alert)}
                                >
                                    View details
                                </span>
                                <div className="visit-details-warnings__text">
                                    {`Unresolved patient alert raised by ${getHcpName(
                                        users,
                                        alert.createdBy,
                                    )} at ${formatDisplayDateTime(alert.createdAt)}: ${alert.type}`}
                                </div>
                            </div>
                        ))}
                    </Callout>
                </div>
            </ConditionalDisplay>
            <ConditionalDisplay show={hasResolvedAlerts}>
                <div className="visit-details__warning">
                    <Callout intent="primary" Icon={IconWarning} spacerTop={false}>
                        {resolved.map((alert: PatientAlert, i: number) => (
                            <React.Fragment
                                key={`visit-details__patient-resolved-alert-${alert.alertId}`}
                            >
                                <div className="visit-details-warnings__button">
                                    <span
                                        className="visit-details-warnings__view-details"
                                        onClick={() => onViewDetailsClick(alert)}
                                    >
                                        View details
                                    </span>
                                    <p>
                                        {`Patient alert resolved by ${getHcpName(
                                            users,
                                            alert.resolvedBy,
                                            false,
                                        )} on ${formatDisplayDateTime(alert.resolvedAt)}: ${
                                            alert.resolution
                                        }`}
                                    </p>
                                </div>

                                <p className="visit-details-warnings__originalalert-text">
                                    {`Original alert raised by ${getHcpName(
                                        users,
                                        alert.createdBy,
                                        false,
                                    )} on ${formatDisplayDateTime(alert.createdAt)}: ${alert.type}`}
                                </p>
                            </React.Fragment>
                        ))}
                    </Callout>
                </div>
            </ConditionalDisplay>
        </>
    );
};

export default observer(InfoPanelAlertsSection);
