import { Schedule } from '@doc-abode/data-models';

export function filterSchedules(schedules?: Schedule[], userId?: string) {
    if (!schedules) {
        return [];
    }

    if (!userId) {
        return schedules;
    }

    return schedules.filter((schedule) => schedule.userId === userId);
}
