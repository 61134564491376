import {
    S1ClientConnectionStates,
    S1ClientConnectionStatus,
    s1ClientConnectionStatusSchema,
} from '@doc-abode/data-models';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { z } from 'zod';
import { baseUrl, headers } from '../../../../api/baseApi';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';

const QUERY_KEY = 's1-client-statuses';

const fetchS1ClientConnectionStatusesResponseSchema = z.object({
    results: z.array(s1ClientConnectionStatusSchema),
});

/**
 * Returns a status based on the last activity and reported status of a client
 */
const calculateStatusForDisplay = (
    lastActivity: string | undefined,
    status: string | undefined,
) => {
    if (!status || !lastActivity || status === 'disconnected')
        return S1ClientConnectionStates.DISCONNECTED;
    const lastActivityTime = moment(lastActivity);
    if (moment().diff(lastActivityTime, 'minutes') > 15) return S1ClientConnectionStates.WARNING;

    return S1ClientConnectionStates.CONNECTED;
};

const fetchS1ClientConnectionStatuses = async (
    rootStore: RootStore,
    displayIntervalMinutes: number,
): Promise<S1ClientConnectionStatus[]> => {
    const userSession = await rootStore.userStore.getUserSession();
    const authToken = userSession?.tokens.id;
    const response = await fetch(`${baseUrl}/s1/clients/statuses`, {
        method: 'GET',
        headers: {
            Authorization: authToken,
            ...headers,
        },
    });
    const responseJson = await response.json();
    const responseParseAttempt =
        fetchS1ClientConnectionStatusesResponseSchema.safeParse(responseJson);
    if (!responseParseAttempt.success) {
        throw new Error('Failed to parse response');
    }
    const results = responseParseAttempt.data.results;
    if (!results || results.length === 0) {
        return [];
    }
    const resultsFilteredByInterval = filterResultsByInterval(displayIntervalMinutes, results);
    const statuses = resultsFilteredByInterval.map((clientConnectionStatus) => {
        return {
            ...clientConnectionStatus,
            status: calculateStatusForDisplay(
                clientConnectionStatus.lastActivity,
                clientConnectionStatus.status,
            ),
        };
    });
    return statuses;
};

const filterResultsByInterval = (intervalMinutes: number, statuses: S1ClientConnectionStatus[]) => {
    const now = moment();
    const cutoff = now.clone().subtract(intervalMinutes, 'minutes');
    return statuses.filter((status) => {
        /* istanbul ignore next */
        if (!status.lastActivity) return false;
        return moment(status.lastActivity).isAfter(cutoff);
    });
};

export const useGetS1ClientConnectionStatuses = (displayIntervalMinutes: number = 24 * 60) => {
    const { RootStore: rootStore } = useStores<{ RootStore: RootStore }>();
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: [QUERY_KEY, displayIntervalMinutes],
        queryFn: () => fetchS1ClientConnectionStatuses(rootStore, displayIntervalMinutes),
        retry: 1,
        refetchInterval: 60_000,
        staleTime: 30_000,
        initialData: () => {
            const cachedData = queryClient.getQueryData<S1ClientConnectionStatus[]>([
                QUERY_KEY,
                displayIntervalMinutes,
            ]);
            return cachedData;
        },
    });
};
