import { useQuery } from '@apollo/client';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { JobStatus, Patient } from '@doc-abode/data-models';
import moment from 'moment';
import { FC, FocusEventHandler, FormEvent, useState } from 'react';
import { GET_JOB_BY_ID } from '../../../../../../graphql/queries/jobs';

import cn from 'classnames';
import { ViewToShow } from '../../../../../../constants/mainConst';
import { presentationNameLMF } from '../../../../../../helpers';
import { filterPatientAlerts, getFollowUpValues } from '../../../../../../helpers/ucr';
import { getFollowUpAdminTimeValues } from '../../../../../../helpers/ucr/getFollowUpAdminTimeValues';
import {
    IconClose,
    IconEdit,
    IconExpandMore,
    IconRefresh,
} from '../../../../../../helpers/ucr/icons';
import { isMultiAssigneeJob } from '../../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { Dialogs } from '../../../../../../stores/UCRStore';
import { formatDisplayDate, formatDisplayTime } from '../../../../../modules/helpers/formatData';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../../v2/components';
import { useJobS1SyncStatusViewModel } from '../../../components/useJobS1SyncStatusViewModel';
import { VisitData } from '../../../forms/AddVisit/AddVisitTypes';
import { abortStatusCheck } from '../../../forms/common';
import { usePatientFlagsToChangeStatus } from '../../../hooks/usePatientFlagsToChangeStatus';
import { useView } from '../../../views/useView';
import S1SyncStatusLabel from './S1SyncStatusLabel';

interface IProps {
    patient: Patient;
    refetch: () => void;
    isAdminTime: boolean;
    isLoading: boolean;
}

const Header: FC<IProps> = ({ patient, refetch, isAdminTime, isLoading }) => {
    const {
        RootStore: {
            ucrStore: {
                focusedUser,
                setOpenedDialog,
                setAbortVisit,
                setReverseVisit,
                setFollowUpVisitData,
                setFollowUpAdminTimeData,
                setOpenedPatientAlertInfo,
                focusedJobId,
                setBottomPanelOpen,
                jobAlerts,
            },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();
    const [showDropDown, setShowDropDown] = useState(false);

    const { clearDeepLink, openDeepLink } = useView();

    const {
        firstName,
        lastName,
        middleName,
        jobStatus,
        buddyJobStatus,
        activityType,
        lastUpdatedBy,
        lastUpdatedDateTime,
        createdBy,
        createDateTime,
        startDateTime,
        dateOfVisit,
    } = patient;

    const { isFirstUser, isDoubleUp, isDisabled } = usePatientFlagsToChangeStatus(patient, true);
    // This is used as a symbol between element and focus logic.
    // No style should be applied to this long,class name
    const dropDownItem = 'drop-down-item-blur-logic-symbol';
    const name = `${isAdminTime ? activityType : ''} ${presentationNameLMF({
        firstName,
        middleName,
        lastName,
    })}`;

    let visitStatus = isFirstUser
        ? Patient.getFriendlyVisitStatus(jobStatus as JobStatus)
        : Patient.getFriendlyVisitStatus(buddyJobStatus as JobStatus);

    if (!visitStatus) {
        visitStatus = Patient.getFriendlyVisitStatus(JobStatus.PENDING);
    }

    const isAborted =
        (isFirstUser && abortStatusCheck(jobStatus)) ||
        (!isFirstUser && buddyJobStatus && abortStatusCheck(buddyJobStatus));

    const isSomeAborted =
        abortStatusCheck(jobStatus) || abortStatusCheck(buddyJobStatus as JobStatus);

    const isCompleted =
        (isFirstUser && jobStatus === JobStatus.COMPLETED) ||
        (!isFirstUser && buddyJobStatus === JobStatus.COMPLETED);

    const isPastTime = moment(startDateTime || dateOfVisit).isBefore(moment(), 'day');

    const { unresolved, resolved, hasUnresolvedAlerts, hasResolvedAlerts } = filterPatientAlerts(
        jobAlerts,
        focusedJobId,
    );

    const onClose = () => {
        clearDeepLink();
        setBottomPanelOpen(false);
    };

    const onRefetch = () => refetch();
    const onAbortVisit = () => {
        if (patient) {
            setAbortVisit(patient);
        }
        setShowDropDown(false);
    };

    const onReverseVisit = () => {
        if (patient) {
            setReverseVisit(patient);
        }
        setShowDropDown(false);
    };

    const onChangeFocusedUser = (e: FormEvent<HTMLInputElement>) => {
        openDeepLink(patient.id, e.currentTarget.value);
    };
    const openDialog = () => {
        setOpenedDialog(isAdminTime ? Dialogs.EDIT_ADMINISTRATIVE_TIME : Dialogs.EDIT_VISIT);
        setShowDropDown(false);
    };

    const createdByHcp = users.find((user: any) => user.userId === createdBy);
    const updatedByHcp = users.find((user: any) => user.userId === lastUpdatedBy);
    const currentVisit = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        pollInterval: 60000,
    }).data?.getJob;

    const onBlur: FocusEventHandler = ({ relatedTarget }) => {
        // Manual typing because this isn't typed properly upstream
        if ((relatedTarget as HTMLElement | null)?.className?.includes(dropDownItem)) {
            // We return here to prevent the buttons being hidden before click event can happen
            return;
        }
        setShowDropDown(false);
    };
    const patientAlert = [...resolved, ...unresolved];

    const { getS1SyncStatusDisplayLabel } = useJobS1SyncStatusViewModel(patient);

    return (
        <header className="visit-details__header">
            <div className="visit-details__header-main-info">
                {isLoading ? (
                    <span className="visit-details__header-text">Loading visit details...</span>
                ) : (
                    <div className="visit-details__header-left-side">
                        <span className="visit-details__header-text">{name}</span>
                        <span
                            className={'visit-details__header-tag drop-down-item-blur-logic-symbol'}
                        >
                            {visitStatus}
                        </span>
                        <S1SyncStatusLabel
                            patient={patient}
                            isBuddy={!isFirstUser}
                            label={`S1 ${getS1SyncStatusDisplayLabel(!isFirstUser).toUpperCase()}`}
                            view={ViewToShow.VISIT_DETAILS}
                        />
                        <Button
                            color={ButtonColors.PINK}
                            Icon={IconEdit}
                            clickEvent={openDialog}
                            elem={ButtonElems.BUTTON}
                        />
                        <Button
                            color={ButtonColors.PINK}
                            Icon={IconRefresh}
                            clickEvent={onRefetch}
                            elem={ButtonElems.BUTTON}
                        />
                        {isDoubleUp && focusedUser && (
                            <RadioGroup
                                className="visit-details__header-user-group"
                                label="Show details for staff member"
                                selectedValue={focusedUser}
                                onChange={onChangeFocusedUser}
                            >
                                <Radio
                                    className="visit-details__header-user-radio"
                                    label="#1"
                                    value="user1"
                                />
                                <Radio
                                    className="visit-details__header-user-radio"
                                    label="#2"
                                    value="user2"
                                />
                            </RadioGroup>
                        )}
                    </div>
                )}
                <div className="visit-details__header-right-side" onBlur={onBlur}>
                    <div className={cn(dropDownItem)}>
                        <Button
                            className="v2__form-submit-button"
                            size={ButtonSizes.MEDIUM}
                            elem={ButtonElems.BUTTON}
                            color={ButtonColors.BLUE}
                            Icon={IconExpandMore}
                            name="Actions"
                            clickEvent={() => setShowDropDown(!showDropDown)}
                            style={{
                                paddingLeft: '10px',
                                marginTop: '0px',
                            }}
                            ariaLabel="visit actions"
                        />

                        {showDropDown && (
                            <div className={cn('visit-details__header-actions-list')}>
                                <button
                                    className={cn(
                                        'visit-details__header-actions-list-row',
                                        dropDownItem,
                                    )}
                                    onClick={openDialog}
                                >
                                    View full details
                                </button>
                                {!isAdminTime && (hasUnresolvedAlerts || hasResolvedAlerts) && (
                                    <>
                                        {patientAlert.map((alert) => (
                                            <button
                                                className={cn(
                                                    'visit-details__header-actions-list-row',
                                                    dropDownItem,
                                                )}
                                                onClick={() => setOpenedPatientAlertInfo(alert)}
                                            >
                                                View alert details
                                            </button>
                                        ))}
                                    </>
                                )}

                                {!isAborted && !isCompleted && (
                                    <button
                                        className={cn(
                                            'visit-details__header-actions-list-row',
                                            dropDownItem,
                                        )}
                                        onClick={onAbortVisit}
                                    >
                                        Cancel
                                    </button>
                                )}

                                {isAborted && !isPastTime && (
                                    <button
                                        className={cn(
                                            'visit-details__header-actions-list-row',
                                            dropDownItem,
                                        )}
                                        onClick={onReverseVisit}
                                    >
                                        Reverse cancellation
                                    </button>
                                )}

                                {patient?.jobStatus && !isAdminTime && (
                                    <button
                                        className={cn(
                                            'visit-details__header-actions-list-row',
                                            dropDownItem,
                                        )}
                                        onClick={() => {
                                            const visitValues: VisitData = getFollowUpValues({
                                                ...currentVisit,
                                            });
                                            setFollowUpVisitData(visitValues);
                                            setOpenedDialog(Dialogs.ADD_NEW_VISIT);
                                        }}
                                    >
                                        Create follow-up visit
                                    </button>
                                )}

                                {patient?.jobStatus && isAdminTime && (
                                    <button
                                        className={cn(
                                            'visit-details__header-actions-list-row',
                                            dropDownItem,
                                        )}
                                        onClick={() => {
                                            const visitValues: Partial<VisitData> =
                                                getFollowUpAdminTimeValues({
                                                    ...currentVisit,
                                                });
                                            setFollowUpAdminTimeData(visitValues);
                                            setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME);
                                        }}
                                    >
                                        Create follow-up admin
                                    </button>
                                )}

                                {isMultiAssigneeJob(patient) && !isSomeAborted && !isCompleted && (
                                    <button
                                        className={cn(
                                            'visit-details__header-actions-list-row',
                                            dropDownItem,
                                        )}
                                        onClick={() => {
                                            setOpenedDialog(Dialogs.CHANGE_DOUBLE_UP_TO_SINGLE);
                                        }}
                                    >
                                        Change to single staff
                                    </button>
                                )}

                                {isDisabled ? (
                                    <button
                                        className={cn(
                                            'visit-details__header-actions-list-row',
                                            dropDownItem,
                                        )}
                                        onClick={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                                    >
                                        Change status
                                    </button>
                                ) : null}
                            </div>
                        )}
                    </div>

                    <Button
                        className="visit-details__close-btn"
                        color={ButtonColors.PINK}
                        Icon={IconClose}
                        clickEvent={onClose}
                        elem={ButtonElems.BUTTON}
                    />
                </div>
            </div>
            <div className="v2__dialog-header-update-by">
                <span className="v2__dialog-header-update-by">
                    created by {createdByHcp?.userName || createdBy} on&nbsp;
                    {formatDisplayDate(createDateTime)}&nbsp;
                    {formatDisplayTime(createDateTime)}
                </span>
                {lastUpdatedDateTime && (
                    <span className="v2__dialog-header-update-by">
                        last updated by {updatedByHcp?.userName || lastUpdatedBy} on&nbsp;
                        {formatDisplayDate(lastUpdatedDateTime)}&nbsp;
                        {formatDisplayTime(lastUpdatedDateTime)}
                    </span>
                )}
            </div>
        </header>
    );
};

export default Header;
